import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { LogisticApi } from "@/api";
export default {
  name: "addgoods",
  components: {},
  props: {},
  data: function data() {
    return {
      countryList: [],
      //国家
      createForm: this.$form.createForm(this, {
        name: "addsize"
      }),
      stateList: [],
      //省
      countryid: "",
      state: "",
      cityList: [],
      //市
      districtList: [] //区
    };
  },

  computed: _objectSpread(_objectSpread({}, mapState("base", ["warehouseList"])), {}, {
    editId: function editId() {
      return this.$route.query.id;
    }
  }),
  mounted: function mounted() {
    this.getCountry();
    if (this.editId) {
      this.getDetail();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions("base", ["getWarehouseList"])), {}, {
    handleAdd: function handleAdd(val) {
      var _this = this;
      if (val || this.showdisable) {
        this.$router.push("/warehouseManagement/index?adType=1");
        return;
      }
      this.createForm.validateFields(function (err, value) {
        if (!err) {
          var params = _objectSpread(_objectSpread({}, value), {}, {
            userid: 0,
            type: 4
          });
          if (!_this.editId) {
            LogisticApi.addwarehouse(params).then(function (res) {
              if (res) {
                _this.$router.push("/warehouseManagement/index");
              }
            });
          } else {
            params = _objectSpread(_objectSpread({}, params), {}, {
              id: _this.editId
            });
            LogisticApi.updatewarehouse(params).then(function (res) {
              if (res) {
                _this.$router.push("/warehouseManagement/index");
              }
            });
          }
        } else {
          console.log(err);
        }
      });
    },
    getDetail: function getDetail() {
      var _this2 = this;
      LogisticApi.detailbyid({
        id: this.editId
      }).then(function (res) {
        _this2.createForm.setFieldsValue(res);
        _this2.countryid = res.countryid;
        _this2.getstateList({
          countryid: _this2.countryid,
          id: _this2.countryid,
          level: 0
        });
        _this2.$nextTick(function () {
          _this2.createForm.setFieldsValue(res);
          setTimeout(function () {
            var cityid = _this2.stateList.filter(function (v) {
              return v.statefullname == _this2.createForm.getFieldValue("state");
            })[0].id;
            // console.log(cityid);
            _this2.getstateList({
              countryid: _this2.countryid,
              id: cityid,
              level: 1
            });
            setTimeout(function () {
              var districtid = _this2.cityList.filter(function (v) {
                return v.cityname == _this2.createForm.getFieldValue("city");
              })[0].id;
              // console.log(districtid);
              _this2.getstateList({
                countryid: _this2.countryid,
                id: districtid,
                level: 2
              });
            }, 1000);
          }, 1000);
        });
      });
    },
    getCountry: function getCountry() {
      var _this3 = this;
      // LogisticApi.getcountryconfig().then((res) => {
      //     this.countryList = res;
      // });
      LogisticApi.getNewCountry({
        scene: 1
      }).then(function (res) {
        _this3.countryList = res;
      });
    },
    //选择国家
    handleSelectChange: function handleSelectChange(e) {
      this.countryid = e;
      this.stateList = [];
      this.cityList = [];
      this.districtList = [];
      // this.countryid == 1 ? this.getstateList() : this.getChinaState();
      this.getstateList({
        countryid: this.countryid,
        id: this.countryid,
        level: 0
      });
      this.createForm.setFieldsValue({
        state: "",
        city: "",
        district: ""
      });
    },
    // 选择州/省
    handleStateChange: function handleStateChange(v, e) {
      this.state = e;
      this.cityList = [];
      this.districtList = [];
      // this.getcityList();
      this.getstateList({
        countryid: this.countryid,
        id: this.state,
        level: 1
      });
      this.createForm.setFieldsValue({
        // state:'',
        city: "",
        district: ""
      });
    },
    // 选择市
    handleCityChange: function handleCityChange(v, e) {
      this.districtList = [];
      this.getstateList({
        countryid: this.countryid,
        id: e,
        level: 2
      });
      this.createForm.setFieldsValue({
        // state:'',
        district: ""
      });
    },
    // 获取美国省
    getstateList: function getstateList(_ref) {
      var _this4 = this;
      var id = _ref.id,
        countryid = _ref.countryid,
        level = _ref.level;
      // LogisticApi.getstate({ countryid: this.countryid }).then((res) => {
      //     this.stateList = res;
      // });
      LogisticApi.getNewAddress({
        id: id,
        countryid: countryid,
        level: level
      }).then(function (res) {
        level == 0 ? _this4.stateList = res : level == 1 ? _this4.cityList = res : _this4.districtList = res;
      });
    },
    // 获取美国市
    getcityList: function getcityList() {
      var _this5 = this;
      LogisticApi.getcounty({
        state: this.state
      }).then(function (res) {
        _this5.cityList = res;
      });
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  })
};